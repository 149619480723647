<template>
    <div>
        <b-row class="text-center">
            <b-col xl="3" lg="4" md="6" cols="12" class="mb-4">
                <b-card title="Utilisation du disque">
                    <doughnut-percentage :proportion="diskProportion" :color="diskColor" :text="diskText"/>
                </b-card>
            </b-col>
            <b-col xl="3" lg="4" md="6" cols="12" class="mb-4">
                <b-card title="Utilisation du CPU">
                    <doughnut-percentage :proportion="cpuProportion" :color="cpuColor" :text="cpuText"/>
                </b-card>
            </b-col>
            <b-col xl="3" lg="4" md="6" cols="12" class="mb-4">
                <b-card title="Utilisation de la RAM">
                    <doughnut-percentage :proportion="ramProportion" :color="ramColor" :text="ramText"/>
                </b-card>
            </b-col>
            <b-col xl="3" lg="4" md="6" cols="12" class="mb-4">
                <b-card title="Utilisation du SWAP">
                    <doughnut-percentage :proportion="swapProportion" :color="swapColor" :text="swapText"/>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="6">
                <webcretarium-end-year/>
            </b-col>
            <b-col cols="12" md="6">
                <webcretarium-discord-bot/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {apiPath} from '@/util/http';

const DoughnutPercentage       = () => import( '@/components/webcretarium/DoughnutPercentage');
const WebcretariumDiscordBot   = () => import('@/components/webcretarium/WebcretariumDiscordBot');
const WebcretariumWarningModal = () => import('@/components/modals/WebcretariumWarningModal');
const WebcretariumEndYear      = () => import('@/components/webcretarium/WebcretariumEndYear');

import {isWebcretaire} from '@/util/auth';

export default {
    name: 'AdminWebcretarium',
    components: {DoughnutPercentage, WebcretariumDiscordBot, WebcretariumEndYear},
    data: () => ({
        serverStatFetchInterval: 0,
        serverStats: {
            disk: {free_space: 0, total_space: 0},
            cpu: [0, 0, 0],
            ram: {total: 0, used: 0, free: 0},
            swap: {total: 0, used: 0, free: 0}
        }
    }),
    computed: {
        diskUsed() {
            return this.serverStats.disk.total_space - this.serverStats.disk.free_space;
        },
        diskProportion() {
            return this.diskUsed / this.serverStats.disk.total_space;
        },
        diskColor() {
            return this.colorFromProportion(this.diskProportion);
        },
        diskText() {
            const used  = Math.floor(this.diskUsed / 1e9);
            const total = Math.floor(this.serverStats.disk.total_space / 1e9);

            return `${used} Go utilisés\nsur ${total} Go`;
        },
        ramProportion() {
            return this.serverStats.ram.used / this.serverStats.ram.total;
        },
        ramColor() {
            return this.colorFromProportion(this.ramProportion);
        },
        ramText() {
            const used  = (this.serverStats.ram.used / 1e6).toFixed(2);
            const total = (this.serverStats.ram.total / 1e6).toFixed(2);

            return `${used} Go utilisés\nsur ${total} Go`;
        },
        swapProportion() {
            return this.serverStats.swap.used / this.serverStats.swap.total;
        },
        swapColor() {
            return this.colorFromProportion(this.swapProportion);
        },
        swapText() {
            const used  = (this.serverStats.swap.used / 1e6).toFixed(2);
            const total = (this.serverStats.swap.total / 1e6).toFixed(2);

            return `${used} Go utilisés\nsur ${total} Go`;
        },
        cpuProportion() {
            return this.serverStats.cpu[1];
        },
        cpuColor() {
            return this.colorFromProportion(this.cpuProportion);
        },
        cpuText() {
            return `Charge CPU\nde ${this.cpuProportion}`;
        }
    },
    methods: {
        colorFromProportion(proportion) {
            let red, green;
            if (proportion <= 0.5) {
                green = 1;
                red   = 2 * proportion;
            } else {
                red   = 1;
                green = 1 - proportion;
            }

            return `rgb(${Math.round(red * 255)}, ${Math.round(green * 255)}, 0)`;
        },
        updateServerStats() {
            this.axios.get(apiPath('webcretaire_server_stats'))
                .then(response => this.serverStats = response.data)
                .catch(() => this.$toaster.error('Impossible de récupérer la liste des statistiques serveur'));
        }
    },
    mounted() {
        this.updateServerStats();
        this.serverStatFetchInterval = setInterval(() => this.updateServerStats(), 5000);

        if (!isWebcretaire()) // Display a warning modal to regular Bureau users
            this.$store.dispatch('modal/create', {component: WebcretariumWarningModal});
    },
    destroyed() {
        clearInterval(this.serverStatFetchInterval);
    }
};
</script>
